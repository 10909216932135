import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalSitioNoDisponibleStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalGlobal: {
      gap: '12px',
    },
    tituloTexto: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      maxWidth: '364px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '95%',
      },
    },

    imagenLogoSaesa: {
      width: ' 120px !important',
      maxWidth: '120px !important',
    },
  })
);

export default ModalSitioNoDisponibleStyles;
